.icon-button {
  width: 40px;
  height: 40px !important;
  padding: 0;
  min-width: 0;
  font-size: 24px;
  border-radius: 50%;
  border-width: 0;
  /* cursor: pointer !important; */
}
.icon-button:focus {
  outline: none;
}
.icon-button-displayPaneLeft {
  /* color: hsl(90, 58%, 90%); */
  color: #ffffff;
  background-color: #7dc832;
}
.icon-button-displayPaneLeft:not(:disabled):hover {
  background: #b1de84;
}
.icon-button-displayPaneLeft:not(:active):hover {
  color: #ffffff;
  background-color: #7dc832;
}
.icon-button-displayPaneLeft:focus {
  outline: none;
}
.icon-button-displayPaneRight {
  color: #ffffff;
  background-color: #00afff;
}
.icon-button-displayPaneCentre {
  color: #ffffff;
  background-color: #00afff;
}
.icon-button-displayPaneCentredisabled {
  color: #ffffff;
  background-color: #99DFFF;
}
.icon-button-genericOne {
  color: rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
  background-color: transparent;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.icon-button svg {
  font-size: 24px;
  width: 100%;
  display: inherit;
  align-items: inherit;
  justify-content: inherit;
}
