.header-container {
  /* padding: 2.5px; */
  width: 100%;
  box-sizing: border-box;
  border-top: 1px solid #808080;
  display: flex;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  margin: 0 0 10px 0;
}
.inner-container {
  display: flex;
  /* width: 100%; */
  padding: 2.5px;
  flex: 6;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
}
.flex-right {
  display: flex;
  justify-content: right;
  align-items: center;
}
.text-right {
  text-align: right;
}
.iguru-icon-container {
  height: 48px !important;
  width: 48px !important;
}
.logo-container {
  height: 48px;
  max-width: 203px;
}
.logo-img {
  height: 100% !important;
  max-width: 100% !important;
}
.input-padding-0 input {
  padding: 0 !important;
  text-align: right;
}
