.inputText {
  font-size: 1.6rem !important;
  display: grid;
  width: 100%;
}
.dossierBg {
  background-color: #fff;
  padding: 0 0 0 5px !important;
  color: rgba(0, 0, 0, 0.87);
  font-size: 1.6rem;
  height: 50px;
  font-weight: 400;
  min-height: initial !important;
}
.accordianLabelPadding {
  padding: 2.5px 5px;
}
.expansionHeader {
  display: flex;
  border-bottom: 1px solid #f2f2f2;
  box-sizing: border-box;
}
/* .expansionHeader div.Mui-expanded {
  padding: 12px 12px 12px 6px !important; */
/* } */
.expansionHeader div.MuiIconButton-root {
  padding: 12px 6px 12px 6px !important;
}
.expansionHeader div:first-child {
  flex: 5;
  margin: 0;
}
.expansionHeader div:last-child {
  width: calc(16.667% - 2px);
  margin: 0;
}
.expansionHeader div:last-child svg {
  width: 2rem;
  height: 2rem;
}
.hover-bg-color {
  background-color: #ffffff;
}
.hover-bg-color:hover {
  background-color: #f2f2f2;
}
/* .expansionHeader :hover {
} */
.expandedPanel {
  background-color: #f2f2f2 !important;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 3px 1px -2px rgba(0, 0, 0, 0.12) !important;
}
.accordionDetails {
  padding: 0px !important;
}
.detailsContactContainer {
  width: 100%;
}
.detailsContactContainer div {
  margin: 0px;
}
.FormBox {
  padding: 0;
  display: flex;
  margin: 0;
  font-size: 1.6rem;
}
.detailsHeight {
  height: 50px;
  /* height: auto !important; */
}
.detailsHeight-two {
  /* height: 50px; */
  height: auto !important;
}
.detsailsPadding {
  /*padding: 2.5px 5px;*/
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 5px !important;
}
.formControlReviewName {
  font-size: 1.6rem;
  width: 100%;
  flex: 4;
}
.formControlReviewName div div {
  /* margin-top: 16px; */
  margin-bottom: 0px;
  width: 100%;
}
.report-label {
  height: 50px;
  margin: auto;
  align-items: center;
  display: inline-flex;
}
.formControlRight {
  padding: 2px 0 0 0;
  transform: none !important;
  display: flex;
  /* align-items: center; */
  width: 100%;
  overflow: hidden;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.formControlRight::-webkit-scrollbar {
  display: none;
}
.verticalScroll {
  overflow-x: scroll;
}
.formControlRight label {
  color: rgba(0, 0, 0, 0.54) !important;
}
.linkText {
  color: #00afff;
  cursor: pointer;
}
.reviewLinkText {
  color: #7dc832;
  cursor: pointer;
}
.showLessMoreListIcon {
  width: 100%;
  color: #808080;
}

.textForLabel {
  font-size: 1.2rem !important;
}
.textForLabelRight {
  line-height: 2rem;
  transform: translate(0, 1.5px) scale(1);
}
.textForLabelRight sup {
  bottom: 1px;
  display: inline-block;
  padding: 1px 5px;
  position: relative;
  border: 1px solid #bfbfbf;
  font-weight: 400;
  border-radius: 16px;
  font-size: 1rem;
  margin-top: 3px;
  line-height: initial;
  vertical-align: middle;
  margin-left: 5px;
  cursor: pointer;
  background-color: #fff;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.textForLabelRight sup .activeLabelItem {
  background-color: #f2f2f2;
}
.careerLabelRight {
  transform: translate(0, 1.5px) scale(1);
  line-height: 50px;
  width: 100%;
  white-space: nowrap;
  text-align: left;
}

.careerLabelRighttransform {
  transform: translate(0, 1.5px) scale(1) !important ;
  line-height: 50px !important;
}
.showLessMoreList {
  display: flex;
  align-items: center;
}
.showLessMoreList svg {
  width: 20px;
  height: 20px;
  /* margin-left: 20px; */
}
.verifiedUser {
  align-items: center;
}
.reports-center {
  font-size: 1rem;
  align-items: center;
}
.verifiedUser img {
  width: 20px;
  height: 20px;
  margin-top: -10px;
}
.verifiedUser button {
  margin: 0 0 0 0;
  height: 20px;
  align-items: flex-start;
}
.verifiedUserTop {
  padding: 0;
}
.verifiedUserTop button {
  /* align-items: center; */
  padding-top: 2px;
}
.keyCardBottomMargin {
  margin-bottom: 5px !important;
}
.assesseesContainerGray {
  display: flex;
  /* padding: 5px !important; */
  align-items: center;
  background-color: #f2f2f2 !important;
  height: 50px;
  border-radius: 0;
}
.siftheaderdivgray {
  height: 30px;
  /* width: 100%; */
  font-size: 1.6rem;
  display: flex;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
  align-items: center;
  flex-direction: column;
}
.siftheaderdivgray span {
  width: 100%;
}
/* pushpa */
.assesseesContainerWhite {
  padding: 0px;
  border-top: 1px solid #bfbfbf;
  box-shadow: unset;
  border-bottom: 1px solid #bfbfbf;
  background-color: white;
}
.contentDatadivTopborderGray {
  border-top: 1px solid #bfbfbf;
  box-sizing: border-box;
}
.contentDatadivGray {
  border-right: 1px solid #bfbfbf;
  border-left: 1px solid #bfbfbf;
  height: 19px;
  align-items: center;
  display: flex;
}
.selected {
  background-color: #97d35a !important;
}
.selectedG {
  background-color: #bfbfbf !important;
}
.secondarySelected {
  background-color: #b1de84;
}
.secondaryGSelected {
  background-color: #d8d8d8;
}
.SelectedG {
  background-color: #bfbfbf;
}
.SelectedColorTwo {
  background-color: #cbcbcb;
}
.removeableSelected {
  background-color: #ccefff;
}
.thirdGselected {
  background-color: #e5e5e5;
}
.thirdselected {
  background-color: #cbe9ad;
}
/* pushpa */
.cultureContainerGreen {
  display: flex;
  padding: 5px;
  align-items: 'center';
  background-color: solid Green;
  height: 100px;
  border-radius: 0;
}
.siftComponentInnerDiv {
  cursor: default !important;
  width: 100%;
  height: 54px;
}
.contentMaindivGray {
  padding: 0px;
  background-color: white;
  border-bottom: 1px solid #bfbfbf;
  border-top: 1px solid #bfbfbf;
  box-shadow: unset;
}
.userCardHeaderContainer {
  flex: 11;
}
.tooltipwidth {
  width: 300px !important;
}
.siftHeaderDiv {
  height: 30px;
  /* width: 100%; */
  font-size: 1.6 rem;
  display: flex;
  text-align: center;
  color: white;
  align-items: center;
}
.siftHeaderDiv span {
  width: 100%;
}
.sifSecondtHeaderDiv span {
  width: 100%;
}
.sifSecondtHeaderDivGray span {
  width: 100%;
}

.sifSecondtHeaderDiv {
  height: 20px;
  width: 100%;
  font-size: 12px;
  color: white;
  display: flex;
  text-align: center;
  align-items: center;
}
.sifSecondtHeaderDivGray {
  height: 20px;
  width: 100%;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  text-align: center;
  align-items: center;
}
.sifSecondHeaderInner {
  height: 20px;
  align-items: center;
  border-left: 0.5px solid #bfbfbf;
  border-right: 0.5px solid #bfbfbf;
}
.iconOne {
  width: 20px !important;
  height: 20px !important;
  /* color: #0000008a !important; */
}
