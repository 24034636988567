/* .loading-icon {
    color: teal;
    font-size: 4rem;
    animation: animate 2s infinite;
} */

.ListLoader {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    background: transparent;
    z-index: 10;
    height: 100%;
    width: 100%;
}


/* @keyframes animate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(720deg);
    }
} */