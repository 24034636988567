.displayPaneFive-main-container {
  height: calc(100vh - 255px) !important;
  overflow: overlay;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.sticky-header {
  top: 0px;
  z-index: 2;
  position: sticky;
  background-color: white;
}
