.main-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;
}
.display-pane-container {
  width: 33.33%;
}
@media only screen and (max-width: 480px), only screen and (max-device-width: 480px) {
  .display-pane-container {
    width: 100%;
  }
}
.main-container-vertical-left{
  border-left:1px solid rgba(0, 0, 0, 0.12);
}
.main-container-vertical-right{
  border-right:1px solid rgba(0, 0, 0, 0.12);
}