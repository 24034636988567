html {
  font-size: 62.5%;
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, sans-serif !important;
  font-size: 1.6rem !important;
  height: 100%;
  overflow: hidden;
}
.iguru-componentinnerdiv {
  padding: 0px 5px !important;
  width: 100%;
  display: flex !important;
  height: 50px;
}
.rstcustom__lineChildren::after{
  background-color: white;
}
.iguru-componentinnerdiv-header {
  padding: 0px 5px !important;
  width: -webkit-fill-available;
  display: flex !important;
  height: 40px;
}
/*assessment delevert*/
.assessmentRadioQuestion {
  min-height: 55px;
  max-height: 110px;
  overflow: hidden;
  margin-right: 0px;
  margin-bottom: 0px;
  padding: 2.5px;
}
.assessmentRadioQuestion span {
  font-size: 1.6rem;
}
.assessmentRadioQuestion span svg {
  width: 20px;
  height: 20px;
}
/* .assessmentRadioQuestion span span p {
  margin-bottom: 0rem;
} */
.assessmentRadioQuestion span span p img {
  height: '105px !important';
}
.assessmentRadioQuestion input {
  height: 20px;
  width: 20px;
}
.assessmentHeaderHr {
  height: 1px;
  margin: 0;
  border: none;
  flex-shrink: 0;
  background-color: rgba(0, 0, 0, 0.12);
}
.badgeBox span {
  top: 10px;
  font-size: 1rem !important;
  color: rgba(0, 0, 0, 0.87) !important;
  right: 22px !important;
}
.notifyIcon {
  position: absolute !important;
  font-size: 2rem !important;
  right: 10px !important;
  color: #0000008a !important;
  top: 0px !important;
  bottom: 0px !important;
  z-index: 99 !important;
  height: 30px !important;
  line-height: 30px !important;
}
.notifyIcon button {
  height: 100%;
  color: #0000008a;
}
/*assessment delevert*/

.iguru-componentinnerdiv-margin {
  /* margin: 0px 5px !important; */
  /* width: 100%; */
  display: flex !important;
  height: 50px;
}
.forgot-and-progress-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 5px;
}
.relatedCardFixed {
  top: 0;
  z-index: 2;
  position: sticky;
  padding: 2.5px;
}
.helperTextCore {
  height: 10px;
}
.iguru-leftpanel {
  flex: 6;
  margin: 0 1px;
}
.iguru-iconbox {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  /* flex-direction: column; */
  /* "& button":{
    height:'50px'
  } */
}
.iguru-iconbox svg {
  font-size: 2rem !important;
  padding:0px;
}
.iguru-iconboxSVG {
  flex: 1;
  color: #808080;
  width: 2rem !important;
  bottom: 0;
  font-size: 2rem !important;
  height: 2rem !important;
  position: relative;
  text-align: center;
  /* margin-right: 4px; */
  justify-content: center;
}

.iguru-iconbox button {
  /* height: 50px;
  width: 50px; */
  padding: 0px;
}

.iguru-iconbox-dashboardcardtop {
  background-color: #fff;
  display: flex;
  cursor: pointer;
  color: #808080;
  height: 50;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250mscubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.iguru-iconbox-dashboardcardtop :hover {
  background-color: #f2f2f2;
}
.imageNA {
  font-size: 4rem;
  background-color: #e5e5e5 !important;
  color: #fff;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 3px 1px -2px rgba(0, 0, 0, 0.12) !important;
}
.imageNA svg {
  height: 4rem;
  width: 4rem;
  border-radius: 50%;
}
.imageNASelected {
  font-size: 4rem;
  background-color: #b2b2b2 !important;
  color: #fff;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 3px 1px -2px rgba(0, 0, 0, 0.12) !important;
}
.imageNASelected svg {
  height: 4rem;
  width: 4rem;
  border-radius: 50%;
}

.containerPadding {
  padding: 2.5px !important;
}
.dossierContainerTop {
  cursor: pointer;
  box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%),
    0px 3px 1px -2px rgb(0 0 0 / 12%) !important;
}
.margin-bottom-10 {
  margin-bottom: 10px !important;
}
.iguru-cardContentMidPanel {
  display: inline-block;
  flex: 4;
  text-align: left;
  height: 50px;
  font-size: 1.6rem;
  overflow: hidden;
  height: inherit;
}
.midPaneInformation {
  color: rgba(0, 0, 0, 0.87);
  width: inherit;
  border: 0;
  margin: 0;
  height: 17px !important;
  padding: 7px 0 6px;
  display: flex;
  font-size: 1.6rem;
  min-width: 0;
  flex-grow: 1;
  background: none;
  align-items: center;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
}
.midPaneInformationScale {
  color: rgba(0, 0, 0, 0.87);
  width: inherit;
  border: 0;
  margin: 0;
  height: 17px !important;
  padding: 7px 0 6px;
  display: flex;
  font-size: 1.2rem;
  min-width: 0;
  flex-grow: 1;
  background: none;
  align-items: center;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
}
.midPaneInformationScale_One {
  color: rgba(0, 0, 0, 0.87);  
  padding: 7px 0 6px;  
  font-size: 1.2rem;  
}
.midPaneLabelScale {
  color: rgba(0, 0, 0, 0.87);
  width: calc(100% - 6px);
  padding: 0;
  overflow: hidden;
  font-size: 1.2rem;
  transform: translate(0, 0.5px) scale(1);
  line-height: normal;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.aliasmiddle {
  height: 49px !important;
  padding: 0 !important;
  display: flex !important;
}

.aliasmiddle {
  height: 50px;
  display: flex !important;
}
.midPaneLabel {
  color: rgba(0, 0, 0, 0.54);
  width: calc(100% - 6px);
  padding: 0;
  overflow: hidden;
  font-size: 1.2rem;
  transform: translate(0, 0.5px) scale(1);
  line-height: normal;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.unitFlex {
  flex: 1;
  display: flex;
  justify-content: center;
}
.assessmenetStatusText {
  text-transform: none;
  font-size: 1rem;
  color: #0000008a;
}
.AssesseeNotifyStatus {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.iconsFooterLabelDefault {
  color: rgba(0, 0, 0, 0.87) !important;
  font-size: 1rem !important;
  padding: 6px 0 0 0 !important;
}

.iconsFooterLabelDisable {
  font-size: 1rem !important;
  padding: 6px 0 0 0 !important;
}
.iconsFooterLabelDefault1 {
  color: rgba(0, 0, 0, 0.87) !important;
  font-size: 1rem !important;
  /* padding: 6px 0 0 0 !important; */
}
.AssesseeNotifyStatusLabel {
  color: rgba(0, 0, 0, 0.54);
  text-transform: lowercase;
}
.dashboardImage {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.iconBoxFooter1 {
  display: flex;
  flex: 1;
  padding-top: 0;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.iconBoxFooterPopup {
  display: flex;
  flex: 1;
  padding: 2.5px 0px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer !important;
}
.iconBoxFooter {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer !important;
}

.unitFlexTop {
  flex-direction: column;
  align-items: center;
  height: 55px;
  /* height: 60px; */
  /* margin: 0.25rem; */
}
.unitFlexTop button {
  height: 20px !important;
}
.iguru-header-badge1_0 {
  display: inline-block;
  border-radius: 16px;
  border: 1px solid;
  padding: 1px 5px;
  position: relative;
  font-size: 1rem;
  font-weight: bold;
  bottom: 1px;
}
.iguru-header-badge1_1 {
  display: inline-block;
  border-radius: 16px;
  border: 1px solid;
  padding: 1px 5px;
  position: relative;
  font-size: 7px;
  /* font-weight: bold; */
  bottom: 1px;
}
.iguru-item-badge1_0 {
  display: inline-block;
  border-radius: 16px;
  border: 1px solid;
  padding: 1px 5px;
  position: relative;
  font-size: 7px;
  /* font-weight: bold; */
  bottom: 1px;
}

.iguru-header-badge1_2 {
  display: inline-block;
  border-radius: 16px;
  border: 1px solid;
  padding: 1px 5px;
  position: relative;
  font-size: 1rem;
  font-weight: bold;
  top: 3px;
}
.font-weight-bold {
  font-weight: bold !important;
}

.svgRootSize {
  font-size: 4rem !important;
  color: #fff;
}
.iconsFooterDefault {
  color: rgba(0, 0, 0, 0.87);
  background-color: transparent;
  box-sizing: border-box;
}
.footerPopupIcons {
  padding: 0px 5px;
  display: block;
  /* width: 300px; */
  flex-wrap: wrap;
}
.form-inputs-cantainer {
  padding: 20px 5px 10px 5px;
}
.form-inputs-signoncantainer {
  padding: 10px 10px 10px 10px;
}
.footerPopupIcons div {
  display: flex;
}
.footerPopupIcons div {
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 2.5px 0px;
}
.footerPopupIcons label {
  font-size: 1rem;
}
.checkedFontNew {
  font-size: 1.2rem;
  flex: 1;
  text-align: right;
  /* right: -9px; */
  height: 25px;
}
.checkedFontNew span:hover {
  background-color: #fff !important;
  /* font-size:1.2rem;
      color:rgba(0, 0, 0, 0.87);
      height: 25px;
      line-height:30px;
          width: auto;
          position: relative;
          right:2.8px; */
}
.likert-flex {
  flex: 1;
}
.parent {
  display: flex;
  flex-wrap: wrap;
}

.child {
  flex: 1 0 15%; /* explanation below */
  margin: 5px;
  height: auto;
}
.likert-display {
  display: flex;
}
.iguru-box-shadow {
  box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%),
    0px 3px 1px -2px rgb(0 0 0 / 12%) !important;
}
.credentialList{
  height: 250px;
  overflow: scroll;
}
.checkedFontNew svg {
  font-size: 1.2rem;
  color: rgba(0, 0, 0, 0.87);
}
.tagInputs div {
  flex: 4;
}
.tagInputs div:first-child {
  flex: 1;
  margin-left: 3px;
}
.tagInputs span {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 5px 7px;
}
.popup-icon-mbPager {
  display: flex;
  flex: 1;
  padding: 2.5px 5px;
  text-align: center;
  justify-content: center;
}
.mbPager {
  display: flex;
  flex: 1;
  padding: 0 5px;
  text-align: center;
  justify-content: center;
}
.mbPager-One{
  display: flex;
  flex: 2;
  padding: 0 5px;
  text-align: center;
  justify-content: center;
}
.helperText {
  font-size: 1.2rem !important;
  color: #f44336 !important;
  width: 100%;
  height: 12px;
}
.helperTextDefault {
  font-size: 1.2rem !important;
  color: rgba(0, 0, 0, 0.54) !important;
  width: 100%;
  height: 12px;
  text-wrap: wrap;
}
.paddingCard {
  padding: 2.5px;
}
/* below css for scroll */
::-webkit-scrollbar {
  width: 4px;
  height: 0px;
  /* height: 2px; */
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-track {
  opacity: 0;
  /*background-color: #b46868;*/
} /* the new scrollbar will have a flat appearance with the set background color */

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
} /* this will style the thumb, ignoring the track */

::-webkit-scrollbar-button {
  /*background-color: #7c2929;*/
} /* optionally, you can style the top and the bottom buttons (left and right for horizontal bars) */

::-webkit-scrollbar-corner {
  background-color: gray;
} /* if */

/* :hover::-webkit-scrollbar {
  width: 4.5px;
} */

.likert_choice {
  flex: 1;
  width: 145px;
  text-align: center;
}
.likert_choice-sclae {
  width: 145px;
  flex: 2;
  text-align: center;
  font-size: 12px;
}
.likert_choice-sclae-One {  
  flex: 2;
  text-align: center;
  font-size: 12px;
  word-break: break-all;
}
@media (min-width: 650px) {
  .codex-editor--narrow .ce-toolbar__actions {
    right: 10px;
  }
}
.audio button {
  display: none;
}

.rounded {
  width: 30rem !important;
  height: 30rem !important;
  border-radius: 50% !important;
}

.scan{
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.scan .qrcode {
  position: relative;
  width: 250px;
  height: 100px;
}

.scan .qrcode::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  animation: animate 4s ease-in-out infinite;
}

@keyframes animate {
  0%,100%{
    height: 0;
  }
  50%{
    height: 100%;
  }
}

/* .scan .qrcode::after{
  content: '';
  position: absolute;
  inset: 20px;
  width: calc(100% - 40px);
  height: 2px;
  background-color: #35fd5c;
  filter: drop-shadow(0 0 20px #35fd5c) drop-shadow(0 0 60px #35fd5c);
  animation: animateLine 4s ease-in-out infinite;
} */

@keyframes animateLine {
  0%{
    height: 20px;
  }
  50%{
    height: calc(100% - 20px);
  }
}

.border{
  position: absolute;
  inset: 0;
  background-color: red;
  background-repeat: no-repeat;
}

.qrscanner{
  min-height: 320px;
  height: 350px;
  max-height: 350px;
  display: block;
  width: 100%;
  text-align: center;
}

.qrscanner::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.qrcontainer {
  height: 200px;
  width: 95%;
  margin: auto;
  display: block;
  background-color: rgba(0, 0, 0, 0.8); 
  /* opacity: 0.5; */
  border: 2px solid white;
  border-radius: 5%;
  /* margin: 1rem; */
}
.QRViewFinder{
  width: 100%;
  height: 100%;
}

.scan
{
  width:100%;
  height:2px;
  /* background-color:rgba(0,0,0,.8); */
  background-color:red;
  /* position:absolute; */
  z-index:9999;
   -moz-animation: scan 4s infinite;
  -webkit-animation: scan 4s infinite;
  animation: scan 4s infinite;
   -webkit-animation-direction: alternate-reverse;
  box-shadow:0px 0px 30px rgba(255,204,102,.5);
  
  
}

@-webkit-keyframes scan {
  0%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(290px);
    transform: translateY(275px);
  }
}

.upload-file-btn{
  display: flex;
  align-items: center;
  justify-content: 'center';
  width: auto;
  height: 2rem;
  border: none;
  cursor: pointer;
  border-radius: 0;
}

.upload-file-btn:hover{
  border-radius: 0;
  background-color:#808080;
}
.upload-file-btn:active{
  border-radius: 0;
}
.font-12 {
  font-size: 1.2rem;
}
.line-height-15 {
  line-height: 1.5rem;
}

/* input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
} */
