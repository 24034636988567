.selectFormText {
  /* margin: 5px 5px !important; */
  margin-left: 5px !important;
  margin-right: 5px !important;
  min-height: 50px;
  display: flex !important;
}
.selectFontAlign {
  font-size: unset !important;
  text-align: left !important;
  width: 290px;
}
.selectFontAlign svg {
  font-size: 24px;
}
.selectMenu {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  font-size: 1.6rem !important;
  height: 48px;
}

.margin-left-right-5 {
  margin-left: 5px !important;
  margin-right: 5px !important;
  width: auto;
}
.selectMenuDisabled {
  pointer-events: none;  
}