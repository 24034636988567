.noTextTranform {
  text-transform: none !important;
}
.leftNavButton {
  display: flex;
  height: 50px;
  padding: 0px;
  font-size: 1.6rem;
  font-weight: 400;
  color: #808080;
}
.midPaneInformation span {
  width: inherit;
  overflow: hidden;
  white-space: nowrap;
  line-height: normal;
  text-overflow: ellipsis;
}
.selectionIcon {
  font-size: 1.2rem;
  position: relative;
  right: 0px;
  color: #0000008a;
}

.iconsBarDefaultFlag {
  display: flex;
  height: 25px;
  flex: 1;
  align-items: center;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* .iconsBarDefaultFlag button {
  color: #0000008a;
  width: 4rem;
  height: 4rem;
} */
.iconsBarDefaultFlag .selectedIconColor {
  color: #0000008a;
}
.assesseeListiconSize {
  font-size: 1.2rem !important;
}
.selectedOption button {
  color: #0000008a;
  height: 50px;
  font-size: 2rem;
}
.selectedOptionList svg {
  color: #0000008a;
  height: 50px;
  font-size: 2rem !important;
}
.selectedOption {
  margin: auto;
}
.selectedOption .selectedIconColor {
  color: #0000008a;
}
.assesseeListCheckBoxSelctedOut {
  height: 0.6em;
}
.flagiconmargin {
  margin-top: -5px;
  font-size: 1.7rem !important;
}
.arrowiconmargin {
  margin-top: -5px;
  font-size: 1.2rem !important;
}
.assesseeListCheckBoxSelctedOut svg {
  height: 1.6rem;
}
.reviewListBorder {
  /* max-width: 290px; */
  height: 50px !important;
}
.cardButton {
  display: block;
  text-align: left;
  align-items: flex-start;
  text-transform: capitalize;
  font-weight: 400;
  color: #808080;
  font-size: 1.6rem;
  width: 100%;
  padding: 0px;
  height: 50;
  border-radius: 2px;
  position: relative;
  border-bottom: 1px solid #bfbfbf;
}
.cardButtonwithouttextTransform {
  display: block;
  text-align: left;
  align-items: flex-start;
  text-transform: none;
  font-weight: 400;
  color: #808080;
  font-size: 1.6rem;
  width: 100%;
  padding: 0px;
  height: 49px !important;
  border-radius: 2px;
  position: relative;
  border-bottom: 1px solid #bfbfbf;
}
.cardButtonwithouttextTransform2 {
  display: block;
  text-align: left;
  align-items: flex-start;
  text-transform: none;
  font-weight: 400;
  color: #808080;
  font-size: 1.6rem;
  width: 100%;
  padding: 0px;
  height: 49px !important;
  border-radius: 2px;
  position: relative;
  border-bottom: 1px solid #bfbfbf;
}

.cardButtonwithouttextTransform2:active {
  background-color: #f2f2f2;
}

.cardButtonwithouttextTransform :hover {
  background-color: #f2f2f2;
}
.componentinnerdiv-iguru {
  padding: 0px 5px !important;
  display: flex !important;
  height: 50px;
}
.measureBox {
  display: flex;
  text-align: center;
  align-items: center;
  height: 50px;
  padding: 0px 0px;
  cursor: pointer;
}
.verifiedUser {
  align-items: flex-start;
  padding-top: 2px;
}
.verifiedUser button {
  margin: 0 0 0 0;
  height: 20px;
  align-items: flex-start;
}
.verifiedUserTop {
  padding: 0;
}
.verifiedUserTop {
  align-items: center;
}
.tooltip-container {
  position: absolute;
  top: 28;
  right: 0;
  left: 0;
  z-index: 1000;
  border: 1px solid;
  padding: 5px;
  background-color: #616161;
  color: #fff;
  font-size: 1.2rem;
  width: 230px;
  white-space: break-spaces;
}
.tooltip-container:hover {
  background-color: #616161;
}
.tooltip-text {
  margin: 0;
}
.tooltip-text:hover {
  background-color: #616161;
}
