.signin-container {
  height: 100vh;
  width: 100%;
  position: relative;
  /* background: #7a7a59; */
}
.form-box {
  width: 300px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.26);
}
.form-header {
  height: 60px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 5px !important;
}

.inner-form-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  box-sizing: border-box;
  width: 100%;
}
.rotate-icon {
  -ms-transform: rotate(180deg); /* IE 9 */
  transform: rotate(180deg);
}
.form-header-logo-container {
  width: 186px;
  height: 43px;
}
.form-header-logo-img {
  width: 100%;
  height: 100%;
}
.sign-in-form-inputs-cantainer {
  padding: 5px;
}
.form-icon-style {
  color: #000000 !important;
  background: #fff !important;
}
.form-icon-style:hover {
  color: #0000008a !important;
  background: #fff !important;
}
