.displayPaneFive-main-container {
  height: calc(100vh - 180px);
  overflow: overlay;
}
/* .MathJax span{
  font-family: Roboto, sans-serif !important;
  font-size: 1.6rem !important;
} */
.midPaneInformationMergeHeader {
  color: rgba(0, 0, 0, 0.87);
  width: inherit;
  border: 0;
  margin: 0;
  height: 17px !important;
  padding: 7px 0 6px;
  text-align: center;
  /* display: flex; */
  font-size: 1.6rem;
  min-width: 0;
  flex-grow: 1;
  background: none;
  align-items: center;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
}
.react-pdf__Page canvas {
  width: 100% !important ;
}
.descriptionMiddle {
  height: 49px !important;
  padding: 0 !important;
  display: flex;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-center-one {
  flex: 1;
  display:flex;
  align-items: center;
  flex-direction: column;
}
.sticky-header {
  top: 0px;
  z-index: 2;
  position: sticky;
  background-color: white;
}
.font-weight-bold {
  color: #000 !important;
  font-weight: bold;
}
.text-area {
  margin: 0px;
  width: 98%;
  height: 105px !important;
}
.radio-button {
  width: 16px !important;
  height: 16px !important;
  margin: 0 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.option-container-likert {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
  /* height: 55px; */
  margin: 0;
}
.flex-center {
  display: flex;
  align-items: center;
}
.option-input {
  margin: 0 !important;
  width: 94% !important;
}
.option-input input {
  font-size: 16px;
}
.icon-button-option {
  display: flex;
  border: 1px solid;
  height: 15px !important;
  width: 15px;
  border-radius: 50%;
  color: gray;
  align-items: center;
  justify-content: center;
  cursor: pointer !important;
}

/* .hr-setup {
  display: flex !important;
  justify-content: space-between !important;
  flex-direction: row !important;
} */

.checkbox-lebel .MuiTypography-body1 {
  font-size: 14px;
  width: 100%;
}
.containerPadding-assessment-header{
  padding: 2.5px;
  /* padding: 0px 2.5px 2.5px 0px; */
}
.assessmentFlagButton{
  margin: auto;
}
.assessmentFlagButton button {
  padding: 0px;
}
.five-assessment-header-flex4{
  display: inline-block; 
  flex: 4;
  overflow-x: scroll;
}
.five-assessment-header-flex1{
  flex: 1 1 0%;
    display: inline-block;
    text-align: center;
    overflow-x: scroll;
}
/* new css */
.ex_container {
  /* min-height: 50px; */
  /* padding: 0px 5px; */
  /* padding: 7px 5px 0px 5px; */
  padding: 0px 5px 0px 5px;
  align-items: center;
  /* overflow: overlay; */
  color: rgba(0, 0, 0, 0.87);
}
.ex_container-One {
  /* min-height: 50px; */
  /* padding: 0px 5px; */
  padding: 7px 5px 0px 0px;
  align-items: center;
  /* overflow: overlay; */
  color: rgba(0, 0, 0, 0.87);
}
.ex_container-One-response-choice {  
  padding: 7px 5px 0px 0px;
  align-items: center;
  text-align: center;
  width: 100%;
  color: rgba(0, 0, 0, 0.87);
}
.ig-label {
  font-size: 1.6rem;
  /* font-weight: bold; */
}
.ndfHFb-c4YZDc-s2gQvd::-webkit-scrollbar{
  height: 6px !important;
  overflow: visible;
  width: 6px !important;
}
.editor-embed body::-webkit-scrollbar{
  height: 6px !important;
  width: 6px !important;
}
.ig-explanation {
  font-size: 1.2rem;
  color: rgba(0, 0, 0, 0.87);
}
.ig-itemGeneric {
  font-size: 1.6rem;
}
.iguru-editor{
  height: 215px;
  overflow: overlay;
  border-radius:4px;
  box-shadow:0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);                       
}
.iguru-editor .ce-paragraph {
  padding-left: 40px !important;
}
.iguru-editor .ce-toolbox {
  /* padding-left: 40px !important; */
  left: -100px;
}
.iguru-editor .ce-block__content{
  max-width: 100% !important;
}
.iguru-editor .ce-toolbar__plus{
  left: -141px;
}
.label {
  height: 50px;
  font-size: 1.6rem;
  padding: 0px 5px;
}

.likartscale {
  padding: 2.5px;
}
.report-trnsform-width{
  text-transform: none;
  width: 100%;
}
.detsailsPadding-one {
  /*padding: 2.5px 5px;*/
  padding-left: 5px;
  padding-right: 5px;
}

.likart,
.option-container {
  display: flex;
  /* padding: 0px 5px; */
  /* padding: 0px 5px 0px 5px; */
}

.likart .likert_choice-sclae {
  font-size: 1.6rem;
  height: 50px;
  /* display: flex; */
  flex: 2;
  align-items: center;
  justify-content:flex-start
}

.likart .item {
  flex: 3;
  /* padding: 0px 2.5px; */
  font-size: 1.6rem;
  min-height: 30px;
  display: flex;
  align-items: center;
}
.likart .item-explanation {
  min-height: 20px;
}

.option-container .item {
  flex: 14;
  padding: 0px 2.5px;
  font-size: 1.6rem;
  height: 50px;
  display: flex;
  align-items: center;
}
.likert-choice-font {
  font-size: 1.6rem;
}
.likert-choice-font-Two {
  font-size: 1.2rem;
}
input[type='radio'] {
  margin: 0px !important;
  height: 15px;
  width: 15px;
}
p {
  margin-top: 7px;
  margin-bottom: 6px;
  line-height: 15px;
}
.innerpadding {
  padding: 15px 2.5px 15px 2.5px;
  /* padding: 2.5px;
  min-height: 50px; */
}
.innerpadding-update {
  padding: 2.5px;
  min-height: 105px
}

.innerheightAdjust {
  /* min-height: 50px; */
}
.innerheightAdjust-new {
  min-height: 50px;
  padding: 2.5px;
}
.assessmentScale{
  word-wrap: break-word;
  display: block;
  cursor: pointer;
}
.assessmentheader {
  color: rgba(0, 0, 0, 0.54);
  padding: 0;
  overflow: hidden;
  text-align: center;
  font-size: 1.2rem;
  transform: translate(0, 0.5px) scale(1);
  line-height: normal;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.responselabel {
  /* width: 15px; */
  /* height: 15px; */
  position: absolute;
  overflow: hidden;
  text-align: center;
  border-radius: 100%;
  font-size: 1.2rem;
  cursor: pointer;
}
.itemcluster {
  /* display: contents; */
  font-weight: bold;
}
input[type='radio'] {
  width: 20px;
  height: 20px;
  border-radius: 15px;
  border: 1px solid #000;
  background-color: white;
  -webkit-appearance: none; /*to disable the default appearance of radio button*/
  -moz-appearance: none;
}

input[type='radio']:focus {
  /*no need, if you don't disable default appearance*/
  outline: none; /*to remove the square border on focus*/
}

input[type='radio']:checked {
  /*no need, if you don't disable default appearance*/
  background-color: #7dc832 !important;
}

/* input[type='radio']:checked ~ span:first-of-type { */
  /* color: white; */
/* } */
.radio-option-right span:first-of-type {
  position: absolute;
  right: 12px;
  top: 5px;
  font-size: 1rem;
}
.radio-option-right{
  padding-right: 5px;
  display: flex;
  align-items: center;
  position: relative;
}
/* .radio-option-right span {
  position: relative;
  top: 0px;
} */
/* input[type='radio']:checked { 
  box-shadow:0 0 0 2px #7dc832;
  border-radius:50%;
  transition:all ease-in 0.2s;
  appearance:none;
  border:2px solid white; 
  height: 13px;
  width: 13px;
} */
.editor-radio {
  height: 30px;
  display: flex;
  margin: 5px 0px 5px 0px;
}
.editor-list{
  width: 100%;
  margin-block-start: 5px;
  margin-block-end: 5px;
  padding-inline-start: 20px;
}
.editor-table tr:first-child{
  vertical-align: top;
  font-weight: bold;
}

.editor-table{
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-collapse: collapse
}

.editor-table tr td:first-child{
  text-align: left !important;
}
.editor-table td{
  text-align:left;
  border: 1px solid rgba(0, 0, 0, 0.12);
  font-size: 1.2rem;
}
.editor-radio-label {
  padding-left: 10px;
}
.editor-text-para{
  line-height: 2.5rem;
  display: block;
}
.editor-text-para u{
  text-decoration: wavy;
  border-bottom: 1px solid;
}
.ct--shown{
  z-index: 1000;
}
.innerpadding-old{
  padding: 2.5px;
}

.rating-scale-container{
  --n: 12;
  --g: 0px;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: calc((100% - (var(--n) - 1)*var(--g))/var(--n));
  grid-gap: var(--g);  
  /* overflow-x: auto; */
}

.rating-scale-container-row{
  --n: 12;
  --g: 0px;
  display: grid;
  grid-auto-flow: row;
  grid-auto-columns: calc((100% - (var(--n) - 1)*var(--g))/var(--n));
  grid-gap: var(--g);  
  /* overflow-x: auto; */
}
.midPaneInformationScale_Vertical{
  color: rgba(0, 0, 0, 0.87);
  /* padding: 7px 0 6px; */
  font-size: 1.6rem;
}
.ce-block__content {
  position: relative;
  /* max-width: 600px; */
  /* margin : 0 auto; */
  -webkit-transition: background-color 0.15s ease;
  transition: background-color 0.15s ease;
}
.codex-editor__redactor{
  padding: 0px !important;
  height: 300px;
}