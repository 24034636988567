.text-container {
  display: inline-block;
  padding: 0;
  margin: 0;
}
.batch-container {
  border: 1px solid;
  bottom: 1px;
  display: inline-block;
  padding: 1px 5px;
  border-radius: 16px;
}
.text-style {
  font-weight: 400;
  padding: 0;
  margin: 0;
}
.text-size-extraSmall {
  font-size: 0.86rem;
}
.text-size-small {
  font-size: 1rem;
}
.text-size-medium {
  font-size: 1.2rem;
}
.text-size-large {
  font-size: 1.6rem;
}
