.popup-form-box {
  margin: 5px 5px;
  min-height: 50px;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
}
.popup-form-box > .label {
  font-size: 1.6rem;
}

.inputFields {
  width: 100%;
}
.inputFields div {
  margin: 0px;
}
.inputFields div label div {
  padding: 1px 0px 1px 0px;
  line-height: 1.6;
}
.inputFields input {
  font-size: 1.6rem;
}
.scanInputFields input {
  font-size: 1.6rem;
  color: #808080 !important;
}
.inputFields textarea {
  font-size: 1.6rem;
}
.popup-form-box label {
  /* margin-left: 5px; */
}
.labelheaderBadge {
  display: inline-block;
  border-radius: 16px;
  border: 1px solid;
  padding: 0 5px;
  position: relative;
  font-size: 1rem;
  text-transform: capitalize;
  top: -2px;
}
.labelheaderBadge1 {
  display: inline-block;
  border-radius: 16px;
  border: 1px solid;
  padding: 0 5px;
  position: relative;
  font-size: 1rem;
  top: -2px;
}

.inputFieldsAdorment p {
  font-size: 1.6rem !important;
}
/* AM/PM */
input[type="time"]::-webkit-datetime-edit-ampm-field {
  visibility: hidden;
}
input[type="time"]::-webkit-calendar-picker-indicator {
  visibility: hidden;
}
.input-disable {
  pointer-events: none;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}
