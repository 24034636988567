.iguru-dashboardCardTop {
  background-color: #fff;
  display: flex;
  cursor: pointer;
  color: #808080;
  height: 50px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.iguru-dashboardCardTop :hover {
  background-color: #f2f2f2;
}

.iguru-rightarrowicon {
  flex: 1;
  color: #808080;
  width: 2rem;
  bottom: 0;
  height: 2rem;
  position: relative;
  text-align: center;
  margin-right: 0px;
  justify-content: center;
}

.iguru-background {
  background-color: rgb(240, 240, 240);
}
.notificationIcon button {
  font-size: 2.4rem;
  color: #0000008a;
}
.notificationIcon svg {
  width: 20px;
  height: 20px;
  color: #0000008a;
}
.badgeBox {
  top: -3;
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.87);
  right: 6;
}

.buttoncss {
  min-width: 0;
  font-size: 1.6rem;
}



