.iguru-usercardcontainer {
  flex: 11;
}
.iguru-img-icon-left{
  align-items: flex-end !important;
  /* margin: auto !important; */
  margin-left: auto !important;
}
.iguru-assesseescontainer {
  display: flex;
  /* width: 100%; */
  padding: 2.5px;
  align-items: center;
  height: 40px;
  border-radius: 0 !important;
}
.iguru-assesseescard {
  color: #808080;
  cursor: pointer;
  height: 40px;
  display: flex;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #fff;
}

.iguru-assesseescontainergreen {
  background-color: #7dc832 !important;
}
.iguru-assesseescontainerblue {
  background-color: #00afff !important;
}
.iguru-headerbadge {
  display: inline-block;
  border-radius: 16px;
  border: 1px solid;
  padding: 0 5px;
  position: relative;
  font-size: 1rem;
  bottom: 1px;
  color: white;
}

.iguru-iconbardefault {
  color: #ffffff;
  font-size: 2rem !important;
  cursor: pointer;
}

.iguru-content {
  width: 100%;
  flex-grow: 1;
  background-color: transparent;
  border: none;
  margin-top: 74px;
}

.iguru-moretextpanelheader {
  color: #ffffff;
  font-weight: bold;
  font-size: 1.6rem;
  flex: 4;
  overflow: hidden;
  line-height: 1.6 !important;
  white-space: nowrap;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.iguru-moretextpanelheader::-webkit-scrollbar {
  display: none;
}
