.middleFooterD {
  display: flex;
  position: absolute;
  width: 33.33%;
  bottom: 10px;
  padding: 2.5px;
  box-sizing: border-box;
  z-index: 1000;
}

.widthDisplayPaneFive {
  width: 66.5%;
}
.fullWidth {
  width: 95%;
}

.footerInner {
  flex: 1;
  padding: 0 5px;
}
.footerInner div {
  padding: 0px;
}
.MuiBottomNavigationCustom {
  /* height: 60px !important; */
  height: 55px !important;
  background: transparent;
  background-color: initial !important;
}
@media only screen and (max-width: 480px),
  only screen and (max-device-width: 480px) {
  .middleFooterD {
    display: flex;
    position: absolute;
    width: 99%;
    bottom: 10px;
    padding: 2.5px;
    box-sizing: border-box;
    z-index: 1000;
  }
}
