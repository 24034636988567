.basic-card-container {
  padding: 2.5px;
  box-sizing: border-box;
  background-color: #fff;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
  border-radius: 2px;
}
.basic-card-label {
  display: block;
}
.basic-card-label-revise {
  display: block;
  cursor: pointer;
}
.basic-card-row {
  padding: 0 5px;
  display: flex;
  align-items: center;
  height: 50px;
}
.label-container {
  display: flex;
  flex-direction: column;
  /* width: 70%; */
  flex: 4;
}
.icon-container {
  padding: 0;
  height: 20px;
  width: 45px;
}
.notifications-icon-container {
  width: 48px;
  color: rgba(0, 0, 0, 0.54);
  height: 48px;
  padding: 0;
  font-size: 1.5rem;
  text-align: center;
  background: #fff;
  border-radius: 50%;
  /* flex: 1; */
}
.flex-one-align-center {
  flex: 1;
  align-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.padding-top-bottom {
  padding: 6px 0 7px 0;
}
.notifications-icon-container:hover {
  background: rgba(0, 0, 0, 0.08);
  cursor: pointer;
}
.iguru-icons-wid-hei {
  height: 20px !important;
  width: 20px !important;
}
.iguru-icons-icon-adjst button span svg{
  height: 20px !important;
  width: 20px !important;
  margin-top: -10px;
}
.notificaton-icon {
  width: 20px !important;
  color: #0000008a !important;
  height: 20px !important;
  margin: 12px 0 0 0;
  font-size: 2rem !important;
}
.icon-font-size{
  font-size: 2rem !important;
}
