.grid-container {
  margin: 0 0 10px 0;
  display: flex;
}
.grid-pane-container {
  flex: 6;
  padding: 2.5px;
  border: 1px dashed black;
}
.grid-inner-container {
  color: rgba(0, 0, 0, 0.54);
  cursor: pointer;
  display: flex;
  padding: 0 5px;
  text-align: center;
  align-items: center;
}
.mb1g {
  display: inline-block;
  flex: 1;
  text-align: center;
  background-color: #7dc832;
  height: 30;
  justify-content: center;
  color: #fff;
}
.mb1b {
  display: inline-block;
  flex: 1;
  text-align: center;
  background-color: #00afff;
  height: 30;
  justify-content: center;
  color: #fff;
}
