.tickOption {
  position: relative;
  color: rgba(0, 0, 0, 0.87);
}
.optionSecondary {
  color: rgba(0, 0, 0, 0.87) !important;
  width: 100% !important;
  cursor: pointer !important;
  height: 30px !important;
  font-size: 1.2rem !important;
  text-align: center !important;
  min-height: inherit !important;
  line-height: 1 !important;
  font-weight: 400 !important;
  text-transform: lowercase !important;
  padding: 2.5px 0px !important;
}
.ce-block__content {
  position: relative;
  max-width: 600px;
  margin: 0 auto;
  -webkit-transition: background-color 0.15s ease;
  transition: background-color 0.15s ease;
}
.ce-toolbar__content {
  max-width: 600px;
  margin: 0 auto;
  position: relative;
}
.cdx-block {
  padding: 0px 5px 5px 5px;
}
.codex-editor--narrow .ce-toolbar__plus {
  left: 0px;
}
.ce-paragraph {
  line-height: 1.6em;
  outline: none;
  padding-left: 30px;
}

.optionTertiary {
  color: rgba(0, 0, 0, 0.87) !important;
  width: 100% !important;
  cursor: pointer !important;
  height: 30px !important;
  font-size: 1.2rem !important;
  text-align: center !important;
  min-height: inherit !important;
  line-height: 1 !important;
  font-weight: 400 !important;
  text-transform: lowercase !important;
  padding: 2.5px 0px !important;
}
.optionForth {
  color: rgba(0, 0, 0, 0.87) !important;
  width: 100% !important;
  cursor: pointer !important;
  height: 30px !important;
  font-size: 1.2rem !important;
  text-align: center !important;
  min-height: inherit !important;
  line-height: 1 !important;
  font-weight: 400 !important;
  text-transform: lowercase !important;
  padding: 2.5px 0px !important;
}
.optionFifth {
  color: rgba(0, 0, 0, 0.87) !important;
  width: 100% !important;
  cursor: pointer !important;
  height: 30px !important;
  font-size: 1.2rem !important;
  text-align: center !important;
  min-height: inherit !important;
  line-height: 1 !important;
  font-weight: 400 !important;
  text-transform: lowercase !important;
  padding: 2.5px 0px !important;
}
.optionSixth {
  color: rgba(0, 0, 0, 0.87) !important;
  width: 100% !important;
  cursor: pointer !important;
  height: 30px !important;
  font-size: 1.2rem !important;
  text-align: center !important;
  min-height: inherit !important;
  line-height: 1 !important;
  font-weight: 400 !important;
  text-transform: lowercase !important;
  padding: 2.5px 0px !important;
}
.optionSeventh {
  color: rgba(0, 0, 0, 0.87) !important;
  width: 100% !important;
  cursor: pointer !important;
  height: 30px !important;
  font-size: 1.2rem !important;
  text-align: center !important;
  min-height: inherit !important;
  line-height: 1 !important;
  font-weight: 400 !important;
  text-transform: lowercase !important;
  padding: 2.5px 0px !important;
}
.optionEight {
  color: rgba(0, 0, 0, 0.87) !important;
  width: 100% !important;
  cursor: pointer !important;
  height: 30px !important;
  font-size: 1.2rem !important;
  text-align: center !important;
  min-height: inherit !important;
  line-height: 1 !important;
  font-weight: 400 !important;
  text-transform: lowercase !important;
  padding: 2.5px 0px !important;
}
.popup-icon-padd {
  padding: 2.5px 0px;
}
.optionSecondaryDisabled {
  color: #0000008a !important;
}
.optionSecondaryDisabled :hover {
  background-color: transparent;
}
.optionPrimary {
  text-align: center;
  height: 30px;
  padding: 2.5px 0px !important;
  min-height: inherit;
  cursor: pointer !important;
  font-size: 1.6rem !important;
  text-transform: lowercase !important;
  line-height: 1 !important;
  color: rgba(0, 0, 0, 0.87);
  width: 100% !important;
  font-weight: 400 !important;
}
.tick {
  position: absolute;
  font-size: "2rem";
  right: 17;
  color: #0000008a;
  top: 0;
  bottom: 0;
  z-index: 99;
  height: 30px;
  line-height: 30px;
}
.tick button {
  height: 100%;
  color: #0000008a;
}
/* .optionPrimary :hover{
  background: #00000014;
} */
/* .optionPrimary :focus {
  background: #00000014;
} */
/* .optionPrimary :active {
  background: #00000091;
} */
.popupContent {
  padding: 2.5px 0px !important;
  text-align: center;
  max-height: 100%;
  overflow: overlay;
  overflow: hidden;
}
.textsheetPopupContent {
  padding: 0px 0px !important;
  /* text-align: center; */
  max-height: 450px;
  overflow: overlay;
  overflow: auto;
}
.popupHeaderTitle {
  padding: 0 !important;
  text-align: right;
  color: rgba(0, 0, 0, 0.87);
  font-size: 1.6rem;
}
.popupHeaderTitle div {
  border-radius: 0;
}
.popupMainHeader {
  width: 300px;
  height: 40px;
  align-items: center;
  padding: 5px;
  display: flex;
}
.titleSolid-displayPaneLefttertiary {
  display: flex;
  height: 40px;
  background-color: #e5f4d6 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.titleSolid-displayPaneLeftsecondary {
  display: flex;
  height: 40px;
  background-color: #b1de84 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.titleSolid-displayPaneLeftprimary {
  display: flex;
  height: 40px;
  background-color: #7dc832 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.titleSolid-displayPaneLeft {
  display: flex;
  height: 40px;
  background-color: #7dc832 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.tick {
  position: absolute !important;
  font-size: 2rem;
  right: 17px;
  color: #0000008a !important;
  top: 0;
  bottom: 0;
  z-index: 99;
  height: 30px;
  line-height: 30px;
}
.tick button {
  height: 100%;
  color: #0000008a;
}
.selectionIcon {
  font-size: 1.5rem !important;
  position: relative;
  right: -2px;
  color: #0000008a;
}

.titleSolid-displayPaneRight {
  display: flex;
  height: 40px;
  background-color: #00afff !important;
}
.titleSolid-genericOne {
  display: flex;
  height: 40px;
  background-color: #00afff !important;
  background-image: linear-gradient(to bottom right, #00afff, #7dc832);
}
.previousToLast {
  transform: rotate(-180deg);
  width: 24px;
  height: 24px;
}
.titleSolid-displayPaneCentre {
  display: flex;
  /* height: 50px; */
  background-color: #00afff !important;
}
.titleSolid-displayPaneCentreprimary {
  display: flex;
  /* height: 50px; */
  background-color: #00afff !important;
}
.titleSolid-displayPaneCentresecondary {
  display: flex;
  /* height: 50px; */
  background-color: #66cfff !important;
}
.titleSolid-displayPaneCentretertiary {
  display: flex;
  /* height: 50px; */
  background-color: #66cfff !important;
}
.titleSolid-genericTwo {
  display: flex;
  /* height: 50px; */
  background-color: rgb(255, 100, 100) !important;
}
.titleLightGreen {
  display: flex;
  /* height: 50px; */
  background-color: #b1de84;
  color: rgba(0, 0, 0, 0.87);
}
.aliasName {
  font-size: 1.2rem !important;
  color: #2b21218a !important;
}
.SMainContainer {
  display: flex;
  margin: 0 0 0px;
  padding: 0 10px 10px;
  border: 1px solid;
  border-color: transparent;
  border-top: 0;
}
.SMainContainer label {
  font-size: 1.6rem;
  color: #808080;
}
.formControlReviewName {
  font-size: 1.6rem;
  width: 100%;
  flex: 4;
}
.formControlReviewName div div {
  /* margin-top: 16px; */
  margin-bottom: 0px;
  width: 100%;
}
.formControlRight {
  padding: 2px 0 0 0;
  transform: none !important;
  display: flex;
  align-items: center;
  width: 100%;
}
.formControlRight label {
  color: rgba(0, 0, 0, 0.54) !important;
}
.topLabelFont {
  font-size: 1.4rem;
}
.titleBox {
  text-align: left;
  flex: 4;
  font-size: 1.6rem;
  color: #ffffff;
  font-weight: bold;
  max-width: 180px;
  white-space: nowrap;
  /* text-overflow: ellipsis; */
  /* overflow: hidden; */
  overflow-x: scroll;
  line-height: 1.6 !important;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.titleBox::-webkit-scrollbar {
  display: none;
}

.textSheetTitleBox {
  text-align: left;
  flex: 4;
  font-size: 1.6rem;
  color: #ffffff;
  font-weight: bold;
  white-space: nowrap;
  /* text-overflow: ellipsis;
  overflow: hidden; */
  line-height: 1.6 !important;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.textSheetTitleBox::-webkit-scrollbar {
  display: none;
}
.backArrow {
  flex: 1;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.backArrow button {
  width: 48px;
  height: 48px;
}
.popupClose {
  color: #ffffff;
  font-size: 2rem !important;
  cursor: pointer;
}
.fixed10PadDim {
  min-width: 255px;
  max-width: 310px;
  box-sizing: border-box;
}
.fixed10PadDim-error {
  max-width: 255px !important;
  box-sizing: border-box;
}
.fixed05PadDim {
  min-width: 300px;
  box-sizing: border-box;
}
.revisePopupContent {
  padding-top: 5px !important;
  padding-right: 5px !important;
  padding-left: 5px !important;
  padding-bottom: 5px !important;
}
.reviewListFixedWidth {
  /* max-width: 287px; */
}
.fitContent {
  height: fit-content;
}
.popupMinHei {
  min-height: 17px;
}
.popupMinHei0 {
  min-height: 0px !important;
}
.PopupFormBox {
  margin: 5px 5px;
  min-height: 65px;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
}
.labelPopupBox {
  min-height: 0px;
  align-items: center;
  flex-direction: row;
}
.labelPopupBox label {
  margin: 0px;
  font-size: 1.2rem !important;
  flex: 4;
  text-align: left;
  /* overflow-x: scroll;
  max-width: 180px;
  white-space: nowrap; */
}
.PopupFormBox label {
  font-size: 1.6rem;
}
.textForLabelPopup {
  line-height: 1.2rem;
}
.textForLabelPopup sup {
  bottom: 1px;
  display: inline-block;
  padding: 0px 3px;
  position: relative;
  border: 1px solid;
  font-weight: 400;
  border-radius: 16px;
  line-height: initial;
  vertical-align: middle;
  margin-left: 3px;
  cursor: pointer;
  background-color: #fff;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.textForLabelPopup sup .activeLabelItem {
  background-color: lightGrey;
}
.popupMargin {
  margin: 5px;
}
.helptextmargin {
  margin-top: 5.5px !important;
  /* margin-left: 5px !important; */
  margin-bottom: 5px !important;
}
.contFlex {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.f4 {
  flex: 4;
  text-align: left;
  font-size: 1.2rem;
  color: rgba(0, 0, 0, 0.87);
  text-transform: lowercase;
}
.f9 {
  flex: 4;
  text-align: left;
  font-size: 1.6rem;
  color: rgba(0, 0, 0, 0.87);
}
.f4notify {
  flex: 4;
  text-align: left;
  font-size: 1.2rem;
  color: rgba(0, 0, 0, 0.87);
  justify-content: flex-start;
}
.f4Disable {
  flex: 4;
  text-align: left;
  font-size: 1.2rem;
  color: #0000008a;
}
.f1 {
  flex: 1;
}
.uploadImageWidthHeight {
  width: 280px !important;
  height: 280px !important;
  border-radius: 50% !important;
}
.headerBadge {
  display: inline-block;
  border-radius: 16px;
  border: 1px solid;
  padding: 0 5px;
  position: relative;
  font-size: 1rem;
}
.infoSymbol {
  flex: 1;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding-left: 6px; */
}
.checkedFontNew span {
  margin-right: -2px !important;
}
.MuiBackdrop-root {
  top: 65px !important;
}
#signOn .MuiBackdrop-root {
  top: 0px !important;
}
.parentDialogue {
  z-index: 1000 !important;
}
.loaderimg div div {
  box-shadow: none;
  background-color: #3f3f3f00;
  border: none;
  -khtml-user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
  cursor: none;
  outline: none;
  /* "& > div:nth-child(2)":{
      boxShadow: 'none',
      backgroundColor:'#3f3f3f00'
  } */
}
.MuiDialog-paperScrollPaper > div {
  top: 74px;
}
.MuiDialog-paperScrollPaper {
  display: flex;
  /* position: fixed !important; */
  max-height: calc(100% - 100px) !important;
  flex-direction: column;
  /* margin: 0px !important; */
  top: 55px;
}
.MuiDialog-paper {
  position: absolute !important;
  top: 55% !important;
  overflow-y: auto !important;
  margin: 0px !important;
  left: 50%;
  transform: translate(-50%, -50%);
}
.editor-popup-header {
  max-width: 600px;
  align-items: center;
  padding: 5px;
  display: flex;
}
.editor-popup-div {
  width: 100%;
  align-items: center;
  padding: 5px;
  display: flex;
}
.editor-popup-content {
  width: 600px;
}
.ce-settings {
  margin-left: -54px;
}
@media only screen and (min-width: 375px) and (max-width: 969px) {
  .editor-popup-header {
    width: 300px;
    align-items: center;
    padding: 5px;
    display: flex;
  }
  .editor-popup-content {
    width: 300px;
  }
  .ce-toolbar--opened {
    transform: translate3d(0px, 0px, 0px) !important;
  }
  .ce-toolbar__content {
    max-width: 300px !important;
    margin: 0px;
    position: relative;
  }
}
@media (max-width: 360px) {
  .MuiDialog-paperScrollPaper {
    display: flex;
    /* position: fixed !important; */
    max-height: calc(100% - 70px) !important;
    flex-direction: column;
    /* margin: 0px !important; */
    top: 55px;
  }

  .textsheetPopupContent {
    width: calc(100vw - 64px);
  }
  .MuiDialog-paper {
    position: absolute !important;
    top: 55% !important;
    overflow-y: auto !important;
    margin: 0px !important;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.jss13409 {
  color: #ff6464;
  z-index: -1;
  font-size: 2rem;
  margin-top: -30px;
}
.sun-editor .se-list-layer {
  left: 60px !important;
  top: 0px !important;
  height: 237px !important;
}

.sun-editor .se-list-layer.se-list-font-size {
  left: 110px !important;
  height: 150px !important;
  top: 45px !important;
}
.sun-editor .se-list-layer.se-list-font-family {
  left: 176px !important;
  top: 45px !important;
  height: 237px !important;
}
.timeFeild {
  width: 13% !important;
  padding: 0px;
  text-align: center;
  /* margin: auto; */
  padding-left: 6px;
}
.MuiInput-underline.Mui-disabled:before {
  border-bottom-style: double !important;
}
.timeSpanPadd {
  padding-top: 7px;
  padding-right: 6px;
}
.in-navigator-icon {
  display: flex;
  flex: 1;
  padding: 0 5px;
  text-align: center;
  justify-content: center;
  padding-top: 5px;
}

.duration-picker-box {
  display: flex;
  align-items: center;
  justify-content: center;
}

.gif-loader {
  border: none;
  background: transparent;
  -khtml-user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
  cursor: none;
  outline: none;
}

.gif-loader:active {
  border: none;
  background: transparent;
  -khtml-user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
  cursor: none;
  outline: none;
}
.gif-loader:focus {
  border: none;
  background: transparent;
  -khtml-user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
  cursor: none;
  outline: none;
}
.popup-error-align {
  text-align: left;
}
.popup-error-content{
  padding: 0 !important;
  margin: 5px 5px;
  display: flex;
  align-items: start;
  flex-direction: column;
  overflow: hidden;
}